import useTypedSelector from "app/hooks/useTypedSelector";
import { selectIsAuthenticated } from "app/slices/auth.slice";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function PrivateRoutes() {
    const location = useLocation();

    const isAuthenticated = useTypedSelector(selectIsAuthenticated);

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
}

export default PrivateRoutes;
