import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

export interface ISessionState {
    activeSession: boolean;
}

const initialState: ISessionState = {
    activeSession: false,
};

const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        startSession: (state) => {
            state.activeSession = true;
        },
        endSession: (state) => {
            state.activeSession = false;
        },
    },
});

export const { startSession, endSession } = sessionSlice.actions;

export const selectActiveSession = (state: RootState) => state.session.activeSession;

export default sessionSlice.reducer;
