import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { showErrorNotification } from "services/notifications.service";

interface IError {
    error: string;
    message: string;
    statusCode: string;
}

const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
    return typeof error === "object" && error !== null && "status" in error;
};

const isApiError = (data: unknown): data is IError => {
    return typeof data === "object" && data !== null && "error" in data && "message" in data;
};

export const handleApiError = (error: unknown, withNotification = true): void => {
    if (!withNotification) return;

    if (isFetchBaseQueryError(error) && isApiError(error.data)) {
        // Depending on the statusCode, we could change the icon to be more meaningful.
        // Some errors may not require a notification and may have other effects.
        showErrorNotification({ title: error.data.error, message: error.data.message });
    } else {
        showErrorNotification({ title: "Oops!", message: "Something went wrong. Please try again." });
    }
};
