import { AppShell, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import useTypedSelector from "app/hooks/useTypedSelector";
import { selectColorScheme } from "app/slices/preferences.slice";
import AppHeader from "layout/AppHeader/AppHeader";
import AppNavbar from "layout/AppNavbar/AppNavbar";
import Main from "layout/Main/Main";

function App() {
    const colorScheme = useTypedSelector(selectColorScheme);

    return (
        <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
            <NotificationsProvider>
                <AppShell
                    padding="md"
                    navbar={<AppNavbar />}
                    header={<AppHeader />}
                    styles={(theme) => ({
                        main: {
                            position: "relative",
                            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
                        },
                    })}
                >
                    <Main />
                </AppShell>
            </NotificationsProvider>
        </MantineProvider>
    );
}

export default App;
