import useTypedSelector from "app/hooks/useTypedSelector";
import { selectIsAuthenticated } from "app/slices/auth.slice";
import { INavigationLinkProps } from "layout/AppNavbar/AppNavbarLinks";
import NavigationItem from "layout/AppNavbar/NavigationItem";
import { Login, Logout } from "tabler-icons-react";

function AppNavbarFooter() {
    const isAuthenticated = useTypedSelector(selectIsAuthenticated);

    const links: INavigationLinkProps[] = [
        {
            icon: <Login size={16} />,
            color: "teal",
            label: "Login",
            to: "/login",
            isVisible: !isAuthenticated,
        },
        {
            icon: <Logout size={16} />,
            color: "red",
            label: "Logout",
            to: "/logout",
            isVisible: isAuthenticated,
        },
    ];

    return (
        <div>
            {links
                .filter((link) => link.isVisible)
                .map((link) => (
                    <NavigationItem
                        icon={link.icon}
                        color={link.color}
                        label={link.label}
                        to={link.to}
                        key={link.label}
                    />
                ))}
        </div>
    );
}

export default AppNavbarFooter;
