import type { BaseQueryFn, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { reauthenticate, unauthenticate } from "app/slices/auth.slice";
import { endSession, startSession } from "app/slices/session.slice";
import type { RootState } from "app/store";
import { Mutex } from "async-mutex";
import { env } from "config";
import { handleApiError } from "services/errorHandling.service";
import { IAuthResponse } from "types/authResponse.interface";
import { Tag } from "types/tag.enum";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: env.alphaUrl,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        const { accessToken } = (getState() as RootState).auth;
        if (accessToken) {
            headers.set("authorization", `Bearer ${accessToken}`);
        }
        return headers;
    },
});

const baseQueryWithReauthentication: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await baseQuery("/refresh-token", api, extraOptions);
                if (refreshResult.data) {
                    api.dispatch(reauthenticate(refreshResult.data as IAuthResponse));
                    api.dispatch(startSession());
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(endSession());
                    api.dispatch(unauthenticate());
                    handleApiError(refreshResult.error);
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    } else if (result.error) {
        handleApiError(result.error);
    }

    return result;
};

export const api = createApi({
    baseQuery: baseQueryWithReauthentication,
    tagTypes: [Tag.Player],
    endpoints: () => ({}),
});
