import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import { IAuthResponse } from "types/authResponse.interface";
import { ITokenResponse } from "types/tokenResponse.interface";

export interface IAuthState {
    accessToken?: string;
    refreshTokenExpires?: Date;
}

const initialState: IAuthState = {
    accessToken: undefined,
    refreshTokenExpires: undefined,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authenticate: (state, action: PayloadAction<IAuthResponse>) => {
            const { accessToken, refreshTokenExpires } = action.payload;
            state.refreshTokenExpires = refreshTokenExpires;
            state.accessToken = accessToken;
        },
        reauthenticate: (state, action: PayloadAction<ITokenResponse>) => {
            const { accessToken } = action.payload;
            state.accessToken = accessToken;
        },
        unauthenticate: (state) => {
            state.accessToken = initialState.accessToken;
            state.refreshTokenExpires = initialState.refreshTokenExpires;
        },
    },
});

export const { authenticate, reauthenticate, unauthenticate } = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectIsAuthenticated = (state: RootState) => !!state.auth.accessToken;
export const selectRefreshTokenExpiry = (state: RootState) => state.auth.refreshTokenExpires;

export default authSlice.reducer;
