import rootReducer, { RootReducerState } from "app/root.reducer";
import { PersistConfig, persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";

const rootPersistConfig: PersistConfig<RootReducerState> = {
    key: "root",
    storage: localStorage,
    whitelist: ["preferences"],
};

const reducer = persistReducer(rootPersistConfig, rootReducer);

export default reducer;
