import { Button, Container, createStyles, Group, Text, Title } from "@mantine/core";
import ErrorCode from "features/NotFound/ErrorCode";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
    container: {
        paddingTop: 80,
        paddingBottom: 80,
    },

    inner: {
        position: "relative",
    },

    image: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 0,
    },

    content: {
        position: "relative",
        paddingTop: 220,
        zIndex: 1,

        [theme.fn.smallerThan("sm")]: {
            paddingTop: 120,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 900,
        fontSize: 38,

        [theme.fn.smallerThan("sm")]: {
            fontSize: 32,
        },
    },

    message: {
        maxWidth: 540,
        margin: "auto",
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl * 1.5,
    },
}));

function NotFound() {
    const { classes } = useStyles();

    return (
        <Container className={classes.container}>
            <div className={classes.inner}>
                <ErrorCode className={classes.image} />
                <div className={classes.content}>
                    <Title align="center" className={classes.title}>
                        Nothing to see here
                    </Title>
                    <Text color="dimmed" size="lg" align="center" className={classes.message}>
                        The page you are trying to access does not exist. You may have mistyped the address, or the page
                        has been moved to another URL.
                    </Text>
                    <Group position="center">
                        <Button variant="subtle" size="md" component={Link} to="/">
                            Take me back to the home page
                        </Button>
                    </Group>
                </div>
            </div>
        </Container>
    );
}

export default NotFound;
