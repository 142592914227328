import type { ColorScheme } from "@mantine/core";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

export interface IPreferencesState {
    colorScheme: ColorScheme;
}

const initialState: IPreferencesState = {
    colorScheme: "light",
};

const preferencesSlice = createSlice({
    name: "preferences",
    initialState,
    reducers: {
        toggleColorScheme: (state) => {
            state.colorScheme = state.colorScheme === "dark" ? "light" : "dark";
        },
    },
});

export const { toggleColorScheme } = preferencesSlice.actions;

export const selectColorScheme = (state: RootState) => state.preferences.colorScheme;

export default preferencesSlice.reducer;
