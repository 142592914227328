import { combineReducers } from "@reduxjs/toolkit";
import { api } from "app/api";
import persistedAuthReducer from "app/persist/auth.persist";
import persistedSessionReducer from "app/persist/session.persist";
import preferencesReducer from "app/slices/preferences.slice";

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: persistedAuthReducer,
    preferences: preferencesReducer,
    session: persistedSessionReducer,
});

export type RootReducerState = ReturnType<typeof rootReducer>;

export default rootReducer;
