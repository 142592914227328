import { useLogoutMutation } from "app/api/auth.api";
import useTypedDispatch from "app/hooks/useTypedDispatch";
import { unauthenticate } from "app/slices/auth.slice";
import { endSession } from "app/slices/session.slice";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

function useLogout() {
    const navigate = useNavigate();

    const dispatch = useTypedDispatch();
    const [logout] = useLogoutMutation();

    const processLogout = useCallback(() => {
        logout()
            .unwrap()
            .then(() => {
                dispatch(endSession());
                dispatch(unauthenticate());
                navigate("/login");
            })
            .catch();
    }, [dispatch, logout, navigate]);

    return { logout: processLogout };
}

export default useLogout;
