import { Navbar } from "@mantine/core";
import AppNavbarFooter from "layout/AppNavbar/AppNavbarFooter";
import AppNavbarLinks from "layout/AppNavbar/AppNavbarLinks";

function AppNavbar() {
    return (
        <Navbar width={{ base: 300 }} p="xs">
            <Navbar.Section grow mt="xs">
                <AppNavbarLinks />
            </Navbar.Section>
            <Navbar.Section>
                <AppNavbarFooter />
            </Navbar.Section>
        </Navbar>
    );
}

export default AppNavbar;
