import { ActionIcon, Group, Header } from "@mantine/core";
import useTypedDispatch from "app/hooks/useTypedDispatch";
import useTypedSelector from "app/hooks/useTypedSelector";
import { selectColorScheme, toggleColorScheme } from "app/slices/preferences.slice";
import Logo from "components/Logo/Logo";
import { MoonStars, Sun } from "tabler-icons-react";

function AppHeader() {
    const colorScheme = useTypedSelector(selectColorScheme);
    const dispatch = useTypedDispatch();

    return (
        <Header height={60}>
            <Group sx={{ height: "100%" }} px={20} position="apart">
                <Logo />
                <ActionIcon variant="default" onClick={() => dispatch(toggleColorScheme())} size={30}>
                    {colorScheme === "dark" ? <Sun size={16} /> : <MoonStars size={16} />}
                </ActionIcon>
            </Group>
        </Header>
    );
}

export default AppHeader;
