import useTypedSelector from "app/hooks/useTypedSelector";
import { selectIsAuthenticated } from "app/slices/auth.slice";
import NavigationItem, { INavigationItemProps } from "layout/AppNavbar/NavigationItem";
import { Adjustments, BrandAppleArcade, Home, Users } from "tabler-icons-react";

export interface INavigationLinkProps extends INavigationItemProps {
    isVisible: boolean;
}

function AppNavbarLinks() {
    const isAuthenticated = useTypedSelector(selectIsAuthenticated);

    const links: INavigationLinkProps[] = [
        {
            icon: <Home size={16} />,
            color: "gray",
            label: "Home",
            to: "/",
            isVisible: true,
        },
        {
            icon: <BrandAppleArcade size={16} />,
            color: "orange",
            label: "Play",
            to: "/play",
            isVisible: true,
        },
        {
            icon: <Users size={16} />,
            color: "pink",
            label: "Players",
            to: "/players",
            isVisible: isAuthenticated,
        },
        {
            icon: <Adjustments size={16} />,
            color: "indigo",
            label: "Settings",
            to: "/settings",
            isVisible: isAuthenticated,
        },
    ];

    return (
        <div>
            {links
                .filter((link) => link.isVisible)
                .map((link) => (
                    <NavigationItem
                        icon={link.icon}
                        color={link.color}
                        label={link.label}
                        to={link.to}
                        key={link.label}
                    />
                ))}
        </div>
    );
}

export default AppNavbarLinks;
