import sessionReducer, { ISessionState } from "app/slices/session.slice";
import { PersistConfig, persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";

const sessionPersistConfig: PersistConfig<ISessionState> = {
    key: "session",
    storage: sessionStorage,
    whitelist: ["activeSession"],
};

const reducer = persistReducer(sessionPersistConfig, sessionReducer);

export default reducer;
