import { LoadingOverlay } from "@mantine/core";
import Logout from "features/Logout/Logout";
import NotFound from "features/NotFound/NotFound";
import PrivateRoutes from "layout/PrivateRoutes/PrivateRoutes";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(() => import("features/Home/Home"));
const LoginRegistration = lazy(() => import("features/LoginRegistration/LoginRegistration"));
const Play = lazy(() => import("features/Play/Play"));
const Players = lazy(() => import("features/Players/Players"));
const Settings = lazy(() => import("features/Settings/Settings"));

function Main() {
    return (
        <Suspense fallback={<LoadingOverlay visible />}>
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/login" element={<LoginRegistration />} />
                <Route path="/logout" element={<Logout />} />

                <Route path="/play" element={<Play />} />

                <Route element={<PrivateRoutes />}>
                    <Route path="/players" element={<Players />} />
                    <Route path="/settings" element={<Settings />} />
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default Main;
