import { LoadingOverlay } from "@mantine/core";
import useLogout from "hooks/useLogout";
import { useEffect } from "react";

function Logout() {
    const { logout } = useLogout();

    // Will logout twice in development due to StrictMode
    useEffect(() => logout(), [logout]);

    return <LoadingOverlay visible />;
}

export default Logout;
