import { LoadingOverlay } from "@mantine/core";
import { useRefreshMutation } from "app/api/auth.api";
import useTypedDispatch from "app/hooks/useTypedDispatch";
import useTypedSelector from "app/hooks/useTypedSelector";
import { reauthenticate, selectRefreshTokenExpiry } from "app/slices/auth.slice";
import { endSession, selectActiveSession, startSession } from "app/slices/session.slice";
import { persistor } from "app/store";
import type { ReactNode } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { isInTheFuture } from "services/dateTime.service";

interface ICustomPersistGateProps {
    children: ReactNode;
}

function CustomPersistGate({ children }: ICustomPersistGateProps) {
    const activeSession = useTypedSelector(selectActiveSession);
    const refreshTokenExpiry = useTypedSelector(selectRefreshTokenExpiry);
    const dispatch = useTypedDispatch();

    const [refresh] = useRefreshMutation();

    // This refresh call will run twice in development due to StrictMode
    const onBeforeLift = async (): Promise<void> => {
        if (activeSession || (!!refreshTokenExpiry && isInTheFuture(refreshTokenExpiry))) {
            await refresh()
                .unwrap()
                .then((payload) => {
                    dispatch(reauthenticate(payload));
                    dispatch(startSession());
                })
                .catch(() => dispatch(endSession()));
        }
    };

    return (
        <PersistGate loading={<LoadingOverlay visible />} onBeforeLift={onBeforeLift} persistor={persistor}>
            {children}
        </PersistGate>
    );
}

export default CustomPersistGate;
