interface IProcessEnv {
    apiUrl: string;
    alphaUrl: string;
    environment: typeof process.env.NODE_ENV;
    publicUrl: typeof process.env.PUBLIC_URL;
}

// Replace these strings at a later date
export const env: IProcessEnv = {
    apiUrl: process.env.REACT_APP_API_URL ?? "/api/v1",
    alphaUrl: process.env.REACT_APP_ALPHA_URL ?? "/api/v1",
    environment: process.env.NODE_ENV,
    publicUrl: process.env.PUBLIC_URL,
};

export const inDevelopmentMode = env.environment === "development";
export const inProductionMode = env.environment === "production";
export const inTestMode = env.environment === "test";
