import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import store from "app/store";
import { inProductionMode } from "config";
import App from "layout/App/App";
import CustomPersistGate from "layout/CustomPersistGate/CustomPersistGate";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "reportWebVitals";

if (inProductionMode) {
    disableReactDevTools();
}

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element!");

const root = createRoot(rootElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <CustomPersistGate>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </CustomPersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
