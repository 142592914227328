import authReducer, { IAuthState } from "app/slices/auth.slice";
import { PersistConfig, persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";

const authPersistConfig: PersistConfig<IAuthState> = {
    key: "auth",
    storage: localStorage,
    whitelist: ["refreshTokenExpires"],
};

const reducer = persistReducer(authPersistConfig, authReducer);

export default reducer;
