import { api } from "app/api";
import { LoginDto } from "dto/auth/login.dto";
import { CreateUserDto } from "dto/user/createUser.dto";
import { IAuthResponse } from "types/authResponse.interface";
import { IUserResponse } from "types/userResponse.interface";

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<IAuthResponse, LoginDto>({
            query: (loginDto) => ({
                url: "/login",
                method: "POST",
                body: loginDto,
            }),
        }),
        logout: build.mutation<void, void>({
            query: () => ({
                url: "/logout",
                method: "POST",
            }),
        }),
        refresh: build.mutation<IAuthResponse, void>({
            query: () => ({
                url: "/refresh-token",
                method: "GET",
            }),
        }),
        register: build.mutation<IUserResponse, CreateUserDto>({
            query: (createUserDto) => ({
                url: "/users",
                method: "POST",
                body: createUserDto,
            }),
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation, useRefreshMutation, useRegisterMutation } = authApi;
